<template>
  <ve-line :data="chartData" :settings="chartSettings" :colors="chartColors" :mark-line="chartMark" :height="chartHeight" :width="chartWidth" :resizeable="true" :theme-name="'dark'"></ve-line>
</template>

<script>
import VeLine from 'v-charts/lib/line.common'
import MarkLine from 'echarts/lib/component/markLine'
import 'v-charts/lib/style.css'

export default {
  props: ['chartData','chartSettings','chartColors','chartMark','chartHeight','chartWidth'],
  components: {
    've-line': VeLine
  }
}
</script>
