<template>
  <BaseLayout :championships="championships" is-page-for-default-championship>
    <BasePage id="athleteDetails" title="Detalhes do Atleta">
      <div class="columns is-multiline is-gapless" v-if="renderAll">
        <div class="column is-auto">
          <!-- Info sobre jogador -->
          <div class="column is-full">
            <div class="info has-background-bluedark2">
              <div class="columns is-vcentered">
                <div class="column" :class="{ centralize: $mq == 'sm' }">
                  <div class="img-player has-text-centered">
                    <div class="img-time">
                      <img :src="atleta.img_time" width="40" />
                      <p class="img-subtitle">{{ atleta.time }}</p>
                    </div>

                    <img :src="atleta.img_atleta" class="img-atleta" />
                  </div>
                  <div class="info-player centralize has-background-bluedark">
                    <div class="centralize-row">
                      <h4 class="h4-title" style="padding-right: 10px;">
                        {{ atleta.apelido }}
                      </h4>
                    </div>
                    <div class="centralize-row is-vcentered">
                      <h4 class="h4-subtitle-new">{{ posicao }}</h4>
                    </div>
                  </div>
                </div>
                <div class="column is-three-fifths">
                  <div class="columns is-multiline">
                    <div class="column is-full">
                      <div class="columns is-mobile">
                        <div class="column is-half">
                          <label class="label-info">Preço</label>
                          <p>C$ {{ atleta.preco }}</p>
                        </div>
                        <div class="column is-half">
                          <label class="label-info"
                            >Média Geral
                            <i
                              class="fa fa-question-circle"
                              v-tooltip.bottom="{
                                content: 'Média em Todos os Mandos',
                                classes: 'home-tooltip',
                              }"
                            ></i>
                          </label>
                          <p>{{ atleta.media.toFixed(2) }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="column is-full">
                      <div class="columns is-mobile">
                        <div class="column is-half">
                          <label class="label-info">Valorização</label>
                          <p
                            :style="{
                              color:
                                atleta.valorizacao > 0
                                  ? 'lightgreen'
                                  : 'lightcoral',
                            }"
                          >
                            <b-icon
                              icon-pack="fas"
                              size="is-small"
                              :icon="
                                atleta.valorizacao > 0
                                  ? 'arrow-up'
                                  : 'arrow-down'
                              "
                            ></b-icon>
                            {{ atleta.valorizacao }}
                          </p>
                        </div>
                        <div class="column is-half">
                          <label class="label-info"
                            >Média em Casa
                            <i
                              class="fa fa-question-circle"
                              v-tooltip.bottom="{
                                content: 'Média do Atleta Jogando em Casa',
                                classes: 'home-tooltip',
                              }"
                            ></i>
                          </label>
                          <p>{{ atleta.media_casa.toFixed(2) }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="column is-full">
                      <div class="columns is-mobile">
                        <div class="column is-half">
                          <label class="label-info"
                            >Mín.p/Valorizar
                            <i
                              class="fa fa-question-circle"
                              v-tooltip.bottom="{
                                content: 'Pontuação Necessária para Valorizar',
                                classes: 'home-tooltip',
                              }"
                            ></i>
                          </label>
                          <p>{{ atleta.valorizacaoMinima.toFixed(2) }}</p>
                        </div>
                        <div class="column is-half">
                          <label class="label-info"
                            >Média Fora
                            <i
                              class="fa fa-question-circle"
                              v-tooltip.bottom="{
                                content: 'Média do Atleta Jogando Fora',
                                classes: 'home-tooltip',
                              }"
                            ></i>
                          </label>
                          <p>{{ atleta.media_fora.toFixed(2) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-mobile is-multiline">
                <div class="column is-full" v-if="atleta.posicao != 'tec'">
                  <div class="columns">
                    <div
                      class="column is-half is-mobile"
                      v-if="scouts.scouts_positivos"
                    >
                      <div
                        class="scouts-pos"
                        v-if="scouts.scouts_positivos.scout.length > 0"
                      >
                        <div
                          v-for="scout of scouts.scouts_positivos.scout"
                          :key="scout.nome"
                        >
                          <span class="scouts-atl"
                            ><b-tag type="is-success has-text-black" rounded>{{
                              scout.valor
                            }}</b-tag></span
                          >
                          <span>{{ scout.nome }}</span>
                        </div>
                      </div>
                      <div v-else class="mg-10 no-content-msg">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon icon="emoticon-sad" size="is-large">
                            </b-icon>
                          </p>
                          <p>Não há registros de scouts positivos.</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="column is-half is-mobile"
                      v-if="scouts.scouts_negativos"
                    >
                      <div
                        class="scouts-neg"
                        v-if="scouts.scouts_negativos.scout.length > 0"
                      >
                        <div
                          v-for="scout of scouts.scouts_negativos.scout"
                          :key="scout.nome"
                        >
                          <span class="scouts-atl"
                            ><b-tag type="is-danger" rounded>{{
                              scout.valor
                            }}</b-tag></span
                          >
                          <span>{{ scout.nome }}</span>
                        </div>
                      </div>
                      <div v-else class="mg-10 no-content-msg">
                        <div class="content has-text-grey has-text-centered">
                          <p>Não há registros de scouts negativos.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-full">
                  <div
                    class="level bg-gray-graph is-mobile has-background-bluedark"
                  >
                    <div class="level-left">
                      <p>Exibir Scouts Por Mando:</p>
                    </div>
                    <div class="level-rigth">
                      <b-button
                        type="is-primary"
                        outlined
                        icon-pack="fas"
                        size="is-small"
                        :icon-right="scout_mando ? 'minus' : 'plus'"
                        @click="scout_mando = !scout_mando"
                      />
                    </div>
                  </div>
                </div>

                <div v-show="scout_mando" class="column is-full">
                  <b-tabs type="is-boxed">
                    <b-tab-item label="Casa">
                      <div class="columns">
                        <div
                          class="column is-half is-mobile"
                          v-if="scouts.scouts_positivos"
                        >
                          <div
                            class="scouts-pos"
                            v-if="scouts.scouts_positivos.casa.length > 0"
                          >
                            <div
                              v-for="scout of scouts.scouts_positivos.casa"
                              :key="scout.nome + '-casa'"
                            >
                              <span class="scouts-atl"
                                ><b-tag
                                  type="is-success has-text-black"
                                  rounded
                                  >{{ scout.valor }}</b-tag
                                ></span
                              >
                              <span>{{ scout.nome }}</span>
                            </div>
                          </div>
                          <div v-else class="mg-10 no-content-msg">
                            Não há registros de scouts positivos em casa.
                          </div>
                        </div>
                        <div
                          class="column is-half is-mobile"
                          v-if="scouts.scouts_negativos"
                        >
                          <div
                            class="scouts-neg"
                            v-if="scouts.scouts_negativos.casa.length > 0"
                          >
                            <div
                              v-for="scout of scouts.scouts_negativos.casa"
                              :key="scout.nome + '-casa'"
                            >
                              <span class="scouts-atl"
                                ><b-tag type="is-danger" rounded>{{
                                  scout.valor
                                }}</b-tag></span
                              >
                              <span>{{ scout.nome }}</span>
                            </div>
                          </div>
                          <div v-else class="mg-10 no-content-msg">
                            Não há registros de scouts negativos em casa.
                          </div>
                        </div>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Fora">
                      <div class="columns">
                        <div
                          class="column is-half is-mobile"
                          v-if="scouts.scouts_positivos"
                        >
                          <div
                            class="scouts-pos"
                            v-if="scouts.scouts_positivos.fora.length > 0"
                          >
                            <div
                              v-for="scout of scouts.scouts_positivos.fora"
                              :key="scout.nome + '-fora'"
                            >
                              <span class="scouts-atl"
                                ><b-tag
                                  type="is-success has-text-black"
                                  rounded
                                  >{{ scout.valor }}</b-tag
                                ></span
                              >
                              <span>{{ scout.nome }}</span>
                            </div>
                          </div>
                          <div v-else class="mg-10 no-content-msg">
                            Não há registros de scouts positivos fora de casa.
                          </div>
                        </div>
                        <div
                          class="column is-half is-mobile"
                          v-if="scouts.scouts_negativos"
                        >
                          <div
                            class="scouts-neg"
                            v-if="scouts.scouts_negativos.fora.length > 0"
                          >
                            <div
                              v-for="scout of scouts.scouts_negativos.fora"
                              :key="scout.nome + '-fora'"
                            >
                              <span class="scouts-atl"
                                ><b-tag type="is-danger" rounded>{{
                                  scout.valor
                                }}</b-tag></span
                              >
                              <span>{{ scout.nome }}</span>
                            </div>
                          </div>
                          <div v-else class="mg-10 no-content-msg">
                            Não há registros de scouts negativos fora de casa.
                          </div>
                        </div>
                      </div>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>

          <!-- Média de Estatisticas por jogo -->
          <div class="column is-full">
            <div class="info has-background-bluedark2">
              <h4 class="title">Média de Estatisticas por Jogo</h4>
              <div class="columns is-mobile is-multiline">
                <div class="column is-full">
                  <div class="subtitle" style="margin-bottom: 10px">
                    <label>Geral</label>
                  </div>
                  <div class="columns">
                    <div
                      class="column is-half is-mobile"
                      v-if="scouts.medias_positivas"
                    >
                      <div
                        class="scouts-pos"
                        v-if="scouts.medias_positivas.media.length > 0"
                      >
                        <div
                          v-for="scout of scouts.medias_positivas.media"
                          :key="scout.nome + '-geral'"
                        >
                          <span class="scouts-atl"
                            ><b-tag type="is-success has-text-black" rounded>{{
                              scout.valor.toFixed(1)
                            }}</b-tag></span
                          >
                          <span>{{ scout.nome }}</span>
                        </div>
                      </div>
                      <div v-else class="mg-10 no-content-msg">
                        Não há registros de scouts positivos.
                      </div>
                    </div>
                    <div
                      class="column is-half is-mobile"
                      v-if="scouts.medias_negativas"
                    >
                      <div
                        class="scouts-neg"
                        v-if="scouts.medias_negativas.media.length > 0"
                      >
                        <div
                          v-for="scout of scouts.medias_negativas.media"
                          :key="scout.nome + '-geral'"
                        >
                          <span class="scouts-atl"
                            ><b-tag type="is-danger" rounded>{{
                              scout.valor.toFixed(1)
                            }}</b-tag></span
                          >
                          <span>{{ scout.nome }}</span>
                        </div>
                      </div>
                      <div v-else class="mg-10 no-content-msg">
                        Não há registros de scouts negativos.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-full">
                  <div
                    class="level bg-gray-graph is-mobile has-background-bluedark"
                  >
                    <div class="level-left">
                      <p>Exibir Média Por Mando:</p>
                    </div>
                    <div class="level-rigth">
                      <b-button
                        type="is-primary"
                        outlined
                        icon-pack="fas"
                        size="is-small"
                        :icon-right="media_mando ? 'minus' : 'plus'"
                        @click="media_mando = !media_mando"
                      />
                    </div>
                  </div>
                </div>

                <div v-show="media_mando" class="column is-full">
                  <b-tabs type="is-boxed">
                    <b-tab-item label="Casa">
                      <div class="columns">
                        <div
                          class="column is-half is-mobile"
                          v-if="scouts.medias_positivas"
                        >
                          <div
                            class="scouts-pos"
                            v-if="scouts.medias_positivas.casa.length > 0"
                          >
                            <div
                              v-for="scout of scouts.medias_positivas.casa"
                              :key="scout.nome + '-casa'"
                            >
                              <span class="scouts-atl"
                                ><b-tag
                                  type="is-success has-text-black"
                                  rounded
                                  >{{ scout.valor.toFixed(1) }}</b-tag
                                ></span
                              >
                              <span>{{ scout.nome }}</span>
                            </div>
                          </div>
                          <div v-else class="mg-10 no-content-msg">
                            Não há registros de scouts positivos em casa.
                          </div>
                        </div>
                        <div
                          class="column is-half is-mobile"
                          v-if="scouts.medias_negativas"
                        >
                          <div
                            class="scouts-neg"
                            v-if="scouts.medias_negativas.casa.length > 0"
                          >
                            <div
                              v-for="scout of scouts.medias_negativas.casa"
                              :key="scout.nome + '-casa'"
                            >
                              <span class="scouts-atl"
                                ><b-tag type="is-danger" rounded>{{
                                  scout.valor.toFixed(1)
                                }}</b-tag></span
                              >
                              <span>{{ scout.nome }}</span>
                            </div>
                          </div>
                          <div v-else class="mg-10 no-content-msg">
                            Não há registros de scouts negativos em casa.
                          </div>
                        </div>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Fora">
                      <div class="columns">
                        <div
                          class="column is-half is-mobile"
                          v-if="scouts.medias_positivas"
                        >
                          <div
                            class="scouts-pos"
                            v-if="scouts.medias_positivas.fora.length > 0"
                          >
                            <div
                              v-for="scout of scouts.medias_positivas.fora"
                              :key="scout.nome + '-fora'"
                            >
                              <span class="scouts-atl"
                                ><b-tag
                                  type="is-success has-text-black"
                                  rounded
                                  >{{ scout.valor.toFixed(1) }}</b-tag
                                ></span
                              >
                              <span>{{ scout.nome }}</span>
                            </div>
                          </div>
                          <div v-else class="mg-10 no-content-msg">
                            Não há registros de scouts positivos fora de casa.
                          </div>
                        </div>
                        <div
                          class="column is-half is-mobile"
                          v-if="scouts.medias_negativas"
                        >
                          <div
                            class="scouts-neg"
                            v-if="scouts.medias_negativas.fora.length > 0"
                          >
                            <div
                              v-for="scout of scouts.medias_negativas.fora"
                              :key="scout.nome + '-fora'"
                            >
                              <span class="scouts-atl"
                                ><b-tag type="is-danger" rounded>{{
                                  scout.valor.toFixed(1)
                                }}</b-tag></span
                              >
                              <span>{{ scout.nome }}</span>
                            </div>
                          </div>
                          <div v-else class="mg-10 no-content-msg">
                            Não há registros de scouts negativos fora de casa.
                          </div>
                        </div>
                      </div>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>

          <!-- Média de Scouts Atleta X Média de Scouts Cedidos pelo Adversário -->
          <div class="column is-full">
            <div class="info has-background-bluedark2">
              <h4 class="title">Média de Scouts</h4>
              <h4 class="subtitle">
                Atleta X Cedidos pelo Adversário nos últimos 5 jogos
                {{
                  (scoutsMediasMando == "casa" ? "em " : "") + scoutsMediasMando
                }}
              </h4>
              <div class="columns is-mobile is-multiline">
                <div class="column is-full">
                  <b-table blueDark :data="scoutsMedias">
                    <template slot-scope="props">
                      <b-table-column
                        field="mediaAtleta"
                        label="Atleta"
                        sortable
                        centered
                      >
                        <div class="scouts-pos" v-if="props.row.tipo == '+'">
                          <span class="scouts-atl"
                            ><b-tag type="is-success has-text-black" rounded>{{
                              props.row.mediaAtleta.toFixed(1)
                            }}</b-tag></span
                          >
                          <span class="icon is-small"></span>
                        </div>
                        <div class="scouts-neg" v-if="props.row.tipo == '-'">
                          <span class="scouts-atl"
                            ><b-tag type="is-danger" rounded>{{
                              props.row.mediaAtleta.toFixed(1)
                            }}</b-tag></span
                          >
                          <span class="icon is-small"></span>
                        </div>
                      </b-table-column>

                      <b-table-column
                        field="nome"
                        label="Scout"
                        sortable
                        centered
                      >
                        {{ props.row.nome }}<span class="icon is-small"></span>
                      </b-table-column>

                      <b-table-column
                        field="mediaAdv"
                        label="Adversário"
                        sortable
                        centered
                      >
                        <div class="scouts-pos" v-if="props.row.tipo == '+'">
                          <span class="scouts-atl"
                            ><b-tag type="is-success has-text-black" rounded>{{
                              props.row.mediaAdv.toFixed(1)
                            }}</b-tag></span
                          >
                          <span class="icon is-small"></span>
                        </div>
                        <div class="scouts-neg" v-if="props.row.tipo == '-'">
                          <span class="scouts-atl"
                            ><b-tag type="is-danger" rounded>{{
                              props.row.mediaAdv.toFixed(1)
                            }}</b-tag></span
                          >
                          <span class="icon is-small"></span>
                        </div>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <p>
                            <b-icon icon="emoticon-sad" size="is-large">
                            </b-icon>
                          </p>
                          <p>Nenhum Registro.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>

          <!-- Times e estatisticas sobre o confronto -->
          <div class="column is-full">
            <div class="info has-background-bluedark2">
              <h4 class="title">Sobre o Confronto</h4>
              <h4 class="subtitle">Informações adicionais sobre o confronto</h4>
              <div class="columns is-multiline is-vcentered">
                <div class="column is-full">
                  <div
                    class="level bg-light has-text-centered has-background-bluedark"
                  >
                    <div class="level-left">
                      <div class="level-item">
                        <!-- <div class="info-row has-text-centered bg-light"> -->
                        <div class="info-row">
                          <div>
                            <label>Chance de Empate</label>
                            <p class="porcentagem">
                              {{ atleta.probabilidade.empate }}%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="level-left">
                      <div class="level-item">
                        <div class="info-row">
                          <div>
                            <label>Empate Sem Gols</label>
                            <p class="porcentagem">
                              {{ atleta.probabilidade.empate_sem_gl }}%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="level-left">
                      <div class="level-item">
                        <div class="info-row">
                          <div>
                            <label>Empate Com 2 ou + Gols</label>
                            <p class="porcentagem">
                              {{ atleta.probabilidade.empate_gols }}%
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>

                <div class="column is-full">
                  <div class="columns is-vcentered" v-if="$mq != 'sm'">
                    <div class="column is-half">
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <img
                              v-if="atleta.mando === 'casa'"
                              :src="atleta.img_time"
                              :alt="atleta.nome_time"
                            />
                            <img
                              v-else
                              :src="atleta.img_time_adv"
                              :alt="atleta.nome_time"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <div class="info-label-content">
                              <label>Placar Provável</label>
                              <p class="porcentagem">
                                {{ atleta.probabilidade.placar.casa }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <div class="info-label-content">
                              <label>Chance de Vencer</label>
                              <p class="porcentagem">
                                {{ atleta.probabilidade.vitoria.casa }}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <div class="info-label-content">
                              <label>Chance de Manter o SG</label>
                              <p class="porcentagem">
                                {{ atleta.probabilidade.sg.casa }}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <div class="info-label-content">
                              <label>Chance de Fazer 2 ou + Gols</label>
                              <p class="porcentagem">
                                {{ atleta.probabilidade.gols.casa }}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="column is-half">
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <img
                              v-if="atleta.mando === 'fora'"
                              :src="atleta.img_time"
                              :alt="atleta.nome_time"
                            />
                            <img
                              v-else
                              :src="atleta.img_time_adv"
                              :alt="atleta.nome_time"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <div class="info-label-content">
                              <label>Placar Provável</label>
                              <p class="porcentagem">
                                {{ atleta.probabilidade.placar.fora }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <div class="info-label-content">
                              <label>Chance de Vencer</label>
                              <p class="porcentagem">
                                {{ atleta.probabilidade.vitoria.fora }}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <div class="info-label-content">
                              <label>Chance de Manter o SG</label>
                              <p class="porcentagem">
                                {{ atleta.probabilidade.sg.fora }}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="level">
                        <div class="level-item">
                          <div class="info-row has-text-centered">
                            <div class="info-label-content">
                              <label>Chance de Fazer 2 ou + Gols</label>
                              <p class="porcentagem">
                                {{ atleta.probabilidade.gols.fora }}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <b-table :data="mbConfronto" narrowed ref="table">
                      <template slot-scope="props">
                        <b-table-column label="">
                          {{ props.row.nome }}
                        </b-table-column>

                        <b-table-column
                          :label="atleta.nome_time"
                          centered
                          numeric
                        >
                          <p
                            :style="{
                              color:
                                props.row.casa > props.row.fora
                                  ? 'lightgreen'
                                  : props.row.casa == props.row.fora
                                  ? 'white'
                                  : 'lightcoral',
                            }"
                          >
                            {{
                              `${props.row.casa}${
                                props.row.nome != "Placar Provável" ? "%" : ""
                              }`
                            }}
                          </p>
                        </b-table-column>

                        <b-table-column
                          :label="atleta.nome_time_adv"
                          centered
                          numeric
                        >
                          <p
                            :style="{
                              color:
                                props.row.fora > props.row.casa
                                  ? 'lightgreen'
                                  : props.row.fora == props.row.casa
                                  ? 'white'
                                  : 'lightcoral',
                            }"
                          >
                            {{
                              `${props.row.fora}${
                                props.row.nome != "Placar Provável" ? "%" : ""
                              }`
                            }}
                          </p>
                        </b-table-column>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-three-fifths">
          <!-- Comparativo das Médias Básicas -->
          <div class="column is-full" v-if="$mq !== 'sm'">
            <div class="info has-background-bluedark2">
              <h4 class="title">Comparativo das Médias Básicas</h4>
              <h4 class="subtitle">
                Média Básica é a Pontuação do Jogador sem (SG, DP, GC, CV, G, A,
                PP)
              </h4>
              <div class="columns is-vcentered">
                <div class="column is-three-quarters">
                  <div
                    class="info-row bg-gray-graph centralize has-background-bluedark"
                    v-if="graphRender"
                  >
                    <pie-chart
                      :chartData="graphPieData"
                      :chartColors="graphColors"
                      :chartSettings="graphPieSettings"
                      :chartHeight="uhd ? '360px' : '300px'"
                      :chartWidth="uhd ? '100%' : '100%'"
                    ></pie-chart>
                    <p class="legenda">
                      {{
                        history.jogos_acima_mb +
                          "/" +
                          (history.jogos_acima_mb + history.jogos_abaixo_mb)
                      }}
                      Jogos Acima da Média Básica
                    </p>
                  </div>
                </div>
                <div class="column is-mobile">
                  <div class="info-row mg-10">
                    <div class="analise has-background-bluedark">
                      <label>Média Básica</label>
                      <p class="row-media">
                        {{ atleta.media_basica.toFixed(2) }}
                      </p>
                      <p class="row-jogos">({{ atleta.jogos }} jogos)</p>
                    </div>
                    <div class="analise has-background-bluedark">
                      <label>Média Básica Casa</label>
                      <p class="row-media">
                        {{ atleta.media_basica_casa.toFixed(2) }}
                      </p>
                      <p class="row-jogos">({{ atleta.jogos_casa }} jogos)</p>
                    </div>
                    <div class="analise has-background-bluedark">
                      <label>Média Básica Fora</label>
                      <p class="row-media">
                        {{ atleta.media_basica_fora.toFixed(2) }}
                      </p>
                      <p class="row-jogos">({{ atleta.jogos_fora }} jogos)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Media Basica x Pontuacao geral, Grafico comparativo -->
          <div class="column is-full" v-if="$mq !== 'sm'">
            <div class="info has-background-bluedark2">
              <h4 class="title">Pontuação Básica x Pontuação Total</h4>
              <h4 class="subtitle">
                Últimos 10 jogos - {{ atleta.mando | capitalize }}
              </h4>
              <div
                class="info-row bg-gray-graph has-background-bluedark"
                v-if="graphRender"
              >
                <line-chart
                  :chartData="{
                    columns: graphLineData.columns,
                    rows: graphLineData.rows,
                  }"
                  :chartColors="graphColors"
                  :chartSettings="graphLineSettings"
                  :chartHeight="$mq == 'lg' ? '400px' : '300px'"
                  chartWidth="auto"
                ></line-chart>
              </div>
            </div>
          </div>

          <!-- Historico de pontuacoes -->
          <div class="column is-full">
            <div class="info has-background-bluedark2">
              <h4 class="title">Histórico de Pontuações</h4>
              <h4 class="subtitle">
                Pontuações do jogador escolhido separado pelo mando
              </h4>

              <b-tabs type="is-boxed">
                <b-tab-item label="Casa">
                  <div v-if="atleta.history.casa.length > 0">
                    <b-table
                      blueDark
                      :data="atleta.history.casa"
                      default-sort="rodada"
                      default-sort-direction="desc"
                    >
                      <template slot-scope="props">
                        <b-table-column
                          field="rodada"
                          label="Rodada"
                          sortable
                          centered
                        >
                          {{ props.row.rodada
                          }}<span class="icon is-small"></span>
                        </b-table-column>

                        <b-table-column label="Confronto" centered>
                          <div
                            style="width: -webkit-fill-available; text-align: center;"
                          >
                            <div class="t-info centralize-row">
                              <img
                                :src="props.row.time_casa"
                                :alt="props.row.nome_time_casa"
                                class="t-img"
                              />
                              <span style="font-size: 16px">{{
                                props.row.placar
                              }}</span>
                              <img
                                :src="props.row.time_fora"
                                :alt="props.row.nome_time_fora"
                                class="t-img"
                              />
                            </div>
                          </div>
                        </b-table-column>

                        <b-table-column
                          field="pontuacao"
                          label="Pontuação"
                          sortable
                          centered
                        >
                          {{ props.row.pontuacao
                          }}<span class="icon is-small"></span>
                        </b-table-column>

                        <b-table-column label="Scouts" centered>
                          <div class="t-scouts">
                            <div v-if="props.row.scouts.positivos.length > 0">
                              <p
                                class="t-s-pos"
                                v-for="(el, i) in props.row.scouts.positivos"
                                :key="el.sigla + '-his-casa-' + i"
                              >
                                {{ el.valor + "" + el.sigla }}
                              </p>
                            </div>
                            <div v-if="props.row.scouts.negativos.length > 0">
                              <p
                                class="t-s-neg"
                                v-for="(el, i) in props.row.scouts.negativos"
                                :key="el.sigla + '-his-casa-' + i"
                              >
                                {{ el.valor + "" + el.sigla }}
                              </p>
                            </div>
                            <div
                              v-if="
                                props.row.scouts.positivos.length == 0 &&
                                  props.row.scouts.negativos.length == 0
                              "
                              class="mg-10 no-content-msg content-msg"
                            >
                              Não há registros.
                            </div>
                          </div>
                        </b-table-column>
                      </template>

                      <template slot="empty">
                        <section class="section">
                          <div class="content has-text-grey has-text-centered">
                            <p>
                              <b-icon icon="emoticon-sad" size="is-large">
                              </b-icon>
                            </p>
                            <p>Nenhum Registro.</p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </div>
                  <div v-else class="mg-10 no-content-msg content-msg">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Nenhum Registro.</p>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="Fora">
                  <div v-if="atleta.history.fora.length > 0">
                    <b-table
                      blueDark
                      :data="atleta.history.fora"
                      default-sort="rodada"
                      default-sort-direction="desc"
                    >
                      <template slot-scope="props">
                        <b-table-column
                          field="rodada"
                          label="Rodada"
                          sortable
                          centered
                        >
                          {{ props.row.rodada
                          }}<span class="icon is-small"></span>
                        </b-table-column>

                        <b-table-column label="Confronto" centered>
                          <div class="t-info centralize-row">
                            <img
                              :src="props.row.time_casa"
                              :alt="props.row.nome_time_casa"
                              class="t-img"
                            />
                            <span style="font-size: 16px">{{
                              props.row.placar
                            }}</span>
                            <img
                              :src="props.row.time_fora"
                              :alt="props.row.nome_time_fora"
                              class="t-img"
                            />
                          </div>
                        </b-table-column>

                        <b-table-column
                          field="pontuacao"
                          label="Pontuação"
                          sortable
                          centered
                        >
                          {{ props.row.pontuacao
                          }}<span class="icon is-small"></span>
                        </b-table-column>

                        <b-table-column label="Scouts" centered>
                          <div class="t-scouts">
                            <div v-if="props.row.scouts.positivos.length > 0">
                              <p
                                class="t-s-pos"
                                v-for="(el, i) in props.row.scouts.positivos"
                                :key="el.sigla + '-his-casa-' + i"
                              >
                                {{ el.valor + "" + el.sigla }}
                              </p>
                            </div>
                            <div v-if="props.row.scouts.negativos.length > 0">
                              <p
                                class="t-s-neg"
                                v-for="(el, i) in props.row.scouts.negativos"
                                :key="el.sigla + '-his-casa-' + i"
                              >
                                {{ el.valor + "" + el.sigla }}
                              </p>
                            </div>
                            <div
                              v-if="
                                props.row.scouts.positivos.length == 0 &&
                                  props.row.scouts.negativos.length == 0
                              "
                              class="mg-10 no-content-msg content-msg"
                            >
                              Não há registros.
                            </div>
                          </div>
                        </b-table-column>
                      </template>

                      <template slot="empty">
                        <section class="section">
                          <div class="content has-text-grey has-text-centered">
                            <p>
                              <b-icon icon="emoticon-sad" size="is-large">
                              </b-icon>
                            </p>
                            <p>Nenhum Registro.</p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </div>
                  <div v-else class="mg-10 no-content-msg content-msg">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Nenhum Registro.</p>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>

          <!-- Histórico de Atletas na Mesma Posição -->
          <div class="column is-full">
            <div class="info has-background-bluedark2">
              <h4 class="title">
                Histórico de Atletas na Mesma Posição (
                {{ atleta.posicao | uppercase }} )
              </h4>
              <h4 class="subtitle">
                Pontuações de jogadores da mesma posicao que jogaram contra o
                mesmo adversario
              </h4>

              <b-tabs type="is-boxed">
                <b-tab-item label="Casa">
                  <div
                    class="limitTabelSamePosition"
                    v-if="listHistory.casa.length > 0"
                  >
                    <b-table
                      blueDark
                      :data="listHistory.casa"
                      default-sort="rodada"
                      default-sort-direction="desc"
                    >
                      <template slot-scope="props">
                        <b-table-column
                          field="rodada"
                          label="Rodada"
                          sortable
                          centered
                        >
                          {{ props.row.rodada
                          }}<span class="icon is-small"></span>
                        </b-table-column>

                        <b-table-column label="Confronto" centered>
                          <div class="t-info centralize-row">
                            <img
                              :src="props.row.time_casa"
                              :alt="props.row.nome_time_casa"
                              class="t-img"
                            />
                            <span style="font-size: 16px">{{
                              props.row.placar
                            }}</span>
                            <img
                              :src="props.row.time_fora"
                              :alt="props.row.nome_time_fora"
                              class="t-img"
                            />
                          </div>
                        </b-table-column>

                        <b-table-column label="Jogador" centered>
                          {{ props.row.nome }}
                        </b-table-column>

                        <b-table-column
                          field="pontuacao"
                          label="Pontuação"
                          sortable
                          centered
                        >
                          {{ props.row.pontuacao
                          }}<span class="icon is-small"></span>
                        </b-table-column>

                        <b-table-column label="Scouts" centered>
                          <!-- {{ props.row.nome }} -->
                          <div class="t-scouts">
                            <div v-if="props.row.scouts.positivos.length > 0">
                              <p
                                class="t-s-pos"
                                v-for="(el, i) in props.row.scouts.positivos"
                                :key="el.sigla + '-his-casa-' + i"
                              >
                                {{ el.valor + "" + el.sigla }}
                              </p>
                            </div>
                            <div v-if="props.row.scouts.negativos.length > 0">
                              <p
                                class="t-s-neg"
                                v-for="(el, i) in props.row.scouts.negativos"
                                :key="el.sigla + '-his-casa-' + i"
                              >
                                {{ el.valor + "" + el.sigla }}
                              </p>
                            </div>
                            <div
                              v-if="
                                props.row.scouts.positivos.length == 0 &&
                                  props.row.scouts.negativos.length == 0
                              "
                              class="mg-10 no-content-msg content-msg"
                            >
                              Não há registros.
                            </div>
                          </div>
                        </b-table-column>
                      </template>

                      <template slot="empty">
                        <section class="section">
                          <div class="content has-text-grey has-text-centered">
                            <p>
                              <b-icon icon="emoticon-sad" size="is-large">
                              </b-icon>
                            </p>
                            <p>Nenhum Registro.</p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </div>
                  <div v-else class="mg-10 no-content-msg content-msg">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Nenhum Registro.</p>
                    </div>
                  </div>
                </b-tab-item>
                <b-tab-item label="Fora">
                  <div
                    class="limitTabelSamePosition"
                    v-if="listHistory.fora.length > 0"
                  >
                    <b-table
                      blueDark
                      :data="listHistory.fora"
                      default-sort="rodada"
                      default-sort-direction="desc"
                    >
                      <template slot-scope="props">
                        <b-table-column
                          field="rodada"
                          label="Rodada"
                          sortable
                          centered
                        >
                          {{ props.row.rodada
                          }}<span class="icon is-small"></span>
                        </b-table-column>

                        <b-table-column label="Confronto" centered>
                          <div class="t-info centralize-row">
                            <img
                              :src="props.row.time_casa"
                              :alt="props.row.nome_time_casa"
                              class="t-img"
                            />
                            <span style="font-size: 16px">{{
                              props.row.placar
                            }}</span>
                            <img
                              :src="props.row.time_fora"
                              :alt="props.row.nome_time_fora"
                              class="t-img"
                            />
                          </div>
                        </b-table-column>

                        <b-table-column label="Jogador" centered>
                          {{ props.row.nome }}
                        </b-table-column>

                        <b-table-column
                          field="pontuacao"
                          label="Pontuação"
                          sortable
                          centered
                        >
                          {{ props.row.pontuacao
                          }}<span class="icon is-small"></span>
                        </b-table-column>

                        <b-table-column label="Scouts" centered>
                          <!-- {{ props.row.nome }} -->
                          <div class="t-scouts">
                            <div v-if="props.row.scouts.positivos.length > 0">
                              <p
                                class="t-s-pos"
                                v-for="(el, i) in props.row.scouts.positivos"
                                :key="el.sigla + '-his-casa-' + i"
                              >
                                {{ el.valor + "" + el.sigla }}
                              </p>
                            </div>
                            <div v-if="props.row.scouts.negativos.length > 0">
                              <p
                                class="t-s-neg"
                                v-for="(el, i) in props.row.scouts.negativos"
                                :key="el.sigla + '-his-casa-' + i"
                              >
                                {{ el.valor + "" + el.sigla }}
                              </p>
                            </div>
                            <div
                              v-if="
                                props.row.scouts.positivos.length == 0 &&
                                  props.row.scouts.negativos.length == 0
                              "
                              class="mg-10 no-content-msg content-msg"
                            >
                              Não há registros.
                            </div>
                          </div>
                        </b-table-column>
                      </template>

                      <template slot="empty">
                        <section class="section">
                          <div class="content has-text-grey has-text-centered">
                            <p>
                              <b-icon icon="emoticon-sad" size="is-large">
                              </b-icon>
                            </p>
                            <p>Nenhum Registro.</p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </div>
                  <div v-else class="mg-10 no-content-msg content-msg">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                      </p>
                      <p>Nenhum Registro.</p>
                    </div>
                  </div>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/apps/Panel/layouts/BaseLayout.vue";
import BasePage from "@/apps/Panel/layouts/BasePage.vue";

import championshipMixins from "@/apps/Panel/mixins/championshipsMixins";

import lineChart from "@/components/helpers/LineChart";
import Pie from "@/components/helpers/PieChart";

export default {
  name: "AthleteDetails",
  mixins: [championshipMixins],
  components: {
    "line-chart": lineChart,
    "pie-chart": Pie,
    BaseLayout,
    BasePage,
  },
  data() {
    return {
      renderAll: false,
      atletaId: 0,
      atleta: {
        history: [],
      },
      history: {},
      optionChart: { responsive: true, maintainAspectRatio: false },
      graphRender: false,
      graphPieData: {},
      graphPieSettings: {},
      graphLineData: {},
      graphLineSettings: {},
      graphColors: ["#30f7ab", "#317df7"],
      listHistory: {
        casa: [],
        fora: [],
      },
      scout_mando: false,
      media_mando: false,
      scouts: {},
      scoutsMedia: [],
      scoutsMediasMando: "",
    };
  },
  computed: {
    url() {
      return url;
    },
    posicao() {
      if (this.atleta.posicao === "gol") return "Goleiro";
      if (this.atleta.posicao === "lat") return "Lateral";
      if (this.atleta.posicao === "le") return "Lateral Esquerdo";
      if (this.atleta.posicao === "ld") return "Lateral Direito";
      if (this.atleta.posicao === "zag") return "Zagueiro";
      if (this.atleta.posicao === "mei") return "Meia";
      if (this.atleta.posicao === "vol") return "Volante";
      if (this.atleta.posicao === "ata") return "Atacante";
      if (this.atleta.posicao === "tec") return "Técnico";
    },
    handleHistoryCasa() {
      return this.atleta.history.casa;
      // return this.atleta.history.casa.filter(el => el.mando_atleta === this.atleta.mando)
    },
    handleHistoryFora() {
      return this.atleta.history.fora;
      // return this.atleta.history.fora.filter(el => el.mando_atleta !== this.atleta.mando)
    },
    uhd() {
      return window.innerWidth > 1390 ? true : false;
    },
    mbConfronto() {
      return [
        {
          nome: "Placar Provável",
          casa: this.atleta.probabilidade.placar.casa,
          fora: this.atleta.probabilidade.placar.fora,
        },
        {
          nome: "Chance de Vencer",
          casa: this.atleta.probabilidade.vitoria.casa,
          fora: this.atleta.probabilidade.vitoria.fora,
        },
        {
          nome: "Chance de Manter o SG",
          casa: this.atleta.probabilidade.sg.casa,
          fora: this.atleta.probabilidade.sg.fora,
        },
        {
          nome: "Chance de Fazer (2 ou +) Gols",
          casa: this.atleta.probabilidade.gols.casa,
          fora: this.atleta.probabilidade.gols.fora,
        },
      ];
    },
  },
  async created() {
    this.atletaId = this.$route.params.aid;
    let loader = this.$loading.show();

    Promise.all([
      this.$http.get(this.url + "/v2/atletas/" + this.atletaId),
      this.$http.get(this.url + "/atletas/" + this.atletaId + "/history"),
      this.$http.get(
        this.url + "/v2/atletas/" + this.atletaId + "/history/list"
      ),
      this.loadChampionships(),
    ])
      .catch((err) => {
        console.log(err);
        loader.hide();
      })
      .then((res) => {
        this.setDefaultToBrazilChampionship();

        this.atleta = res[0].data.data;
        this.history = res[1].data.data;
        this.listHistory = res[2].data.data;
        this.listHistory.casa = this.listHistory.lista.filter(
          (el) => el.mando_atleta === "casa"
        );
        this.listHistory.fora = this.listHistory.lista.filter(
          (el) => el.mando_atleta === "fora"
        );
        loader.hide();

        this.scouts = this.atleta.scouts;
        this.scoutsMedias = this.atleta.scoutsMedias;
        this.scoutsMediasMando = this.atleta.scoutsMediasMando;
        /*
      this.medias.positivos = this.atleta.scouts.scouts_positivos
      this.medias.negativos = this.atleta.scouts.scouts_negativos
      this.medias.medias_positivas = this.atleta.scouts.medias_positivas
      this.medias.medias_negativas = this.atleta.scouts.medias_negativas
      */
        let obj = {
          labels: ["Abaixo da Media Basica", "Acima da Media Basica"],
          datasets: [
            {
              label: "Acima da Media Basica",
              backgroundColor: ["#e100ff", "#ff9900"],
              data: [this.history.jogos_abaixo_mb, this.history.jogos_acima_mb],
            },
          ],
        };
        this.graph = obj;
        this.graphPieSettings = {
          selectedMode: "single",
          hoverAnimation: true,
          radius: 110,
          offsetY: 180,
          label: { normal: { show: false } },
        };
        this.graphPieData = {
          columns: ["analise", "valor"],
          rows: [
            { analise: "Abaixo da Média", valor: this.history.jogos_abaixo_mb },
            { analise: "Acima da Média", valor: this.history.jogos_acima_mb },
          ],
        };
        this.graphLineSettings = {
          legendName: {
            basica: "Pontuação Básica",
            total: "Pontuação Total",
          },
          // ,
          // xAxisType: 'value',
        };
        this.graphLineData = {
          columns: ["rodada", "basica", "total"],
          rows: [],
        };

        let list;
        if (this.atleta.mando == "casa") list = this.handleHistoryCasa;
        else list = this.handleHistoryFora;
        list = list.sort(function(a, b) {
          if (a.rodada > b.rodada) return 1;
          else if (a.rodada < b.rodada) return -1;
          else return 0;
        });
        for (const graphData of list) {
          this.graphLineData.rows.push({
            rodada: graphData.rodada.toString(),
            basica: graphData.pontuacao_basica,
            total: graphData.pontuacao,
          });
        }
        this.graphRender = true;
        this.renderAll = true;
      });
  },
  methods: {
    colorMando(mando) {
      return main.colorMando(mando);
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) and (max-width: 1390px) {
  .info-block label,
  .info-row label {
    font-size: 0.8rem !important;
  }
  .label-info {
    font-size: 0.76rem !important;
  }
  .t-s-pos,
  .t-s-neg {
    font-size: 0.65rem !important;
  }
}
.limitTabelSamePosition {
  height: 72vh;
  overflow-y: auto;
}
.info-label-content {
  padding-bottom: 5px;
  border-bottom: 1px solid #333333;
}
.info-label-content label {
  font-size: 0.75rem !important;
  /* opacity: 0.7; */
}
.scouts-atl {
  width: 40px;
  display: inline-flex;
  color: white;
}
.content-msg {
  float: left;
  padding: 10px;
}
.bg-gray-graph {
  background-color: #333333;
  border-radius: 7px;
  color: white;
  padding: 10px;
}
.col-graph {
  margin-top: 15px;
  margin-right: 5px;
  width: calc(65% - 20px) !important;
}
.col-graph-2 {
  margin-top: 15px;
  width: 35% !important;
}
.info-header-details {
  width: 100%;
  padding: 10px 0;
  float: left;
}
.analise {
  display: inline-block;
  text-align: center;
  width: calc(100% - 10px);
  padding: 5px;
  margin: 5px;
  border: 2px solid #eeeeee;
  border-radius: 5px;
}
.analise p {
  margin: 0;
}
.row-jogos {
  font-weight: normal;
  font-size: 0.7em;
}
.legenda {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.bg-light {
  border-radius: 10px;
  padding: 10px;
  color: white;
  border: 2px solid #00fffd;
}
.tx-center {
  text-align: center;
}
.tx-center .info-row {
  color: #4d4d4d !important;
}
.porcentagem {
  font-size: 1.2rem;
}
.extenso {
  font-size: 14px !important;
}
.details {
  width: 100%;
  height: 100%;
}
.container-col {
  float: left;
  width: calc(50% - 7.5px);
}
.container-col:first-child {
  margin-right: 15px;
}
.mg-10 {
  margin-top: 10px;
}
.bd-title {
  padding-bottom: 5px;
  border-bottom: 2px solid #ffffff;
}
.bd-top {
  border-top: 2px solid #ffffff;
}
.bd-left {
  border-left: 2px solid #ffffff;
}
.col-mid {
  float: left;
  width: 100%;
  display: table;
  margin: 7.5px 0;
}
.col-mid:first-child {
  margin-top: 0;
}
.info {
  width: 100%;
  background-color: #4d4d4d;
  border-radius: 7px;
  padding: 10px;
  display: table;
}
.col-sm {
  float: left;
  width: 40%;
  height: 140px;
  display: table;
}
.col-lg {
  float: left;
  width: 60%;
  display: table;
}
.table-cell {
  border-bottom: 2px solid #ffffff;
  margin-top: 10px;
}
.table-cell:last-child {
  border: none;
}
.img-player {
  width: 100%;
  max-width: 250px;
  position: relative;
}
.img-atleta {
  position: relative;
  left: 0%;
  z-index: 1;
}
.info-player {
  width: 100%;
  max-width: 250px;
  color: white;
  /* float: left; */
  z-index: 2;
  margin-top: -5px;
  padding-top: 10px;
  background-color: #333333;
  border-radius: 10px;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
}
.img-time {
  /* display: block;
  float: left; */
  position: absolute;
  top: 0;
  left: 0;
}
.img-subtitle {
  margin-top: 0;
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
}
h4 i {
  font-size: 20px;
  margin-left: 10px;
}
.h4-subtitle-new {
  width: 100%;
  padding: 0 10px 15px 10px;
  font-size: 0.8rem;
  font-weight: normal;
  margin-top: 5px;
  text-align: center;
}
.h4-title {
  font-size: 18px;
  margin: 0;
}
.info-title {
  float: left;
  width: 100%;
  padding: 5px 0;
}
.info-row {
  float: left;
  width: 100%;
}
.info-container {
  border-top: 2px solid #ffffff;
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
  text-align: left;
  float: left;
}
.info-block {
  width: 50%;
  text-align: left;
  float: left;
  display: table;
  padding: 5px 0;
}
.info-block label,
.info-title label,
.info-row label {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.info-block p,
.info-row p {
  margin-top: 1px;
}
@media (min-width: 342px) and (max-width: 748px) {
  .info-block label,
  .info-title label,
  .info-row label {
    font-size: 0.8rem !important;
  }
  .info-block p,
  .info-row p {
    font-size: 1rem !important;
  }
  .t-info {
    height: 100%;
    width: auto !important;
    padding: 0 0 !important;
  }
  .label-info {
    font-size: 0.75rem !important;
  }
}
.sm-block {
  width: 30% !important;
}
.space-left {
  margin-left: 5%;
}
/* .scouts-pos {color: rgb(116, 255, 116)}
.scouts-neg {color: #ff967c} */
.scouts-pos,
.scouts-neg {
  color: white;
}
.scouts-pos span,
.scouts-neg span {
  margin-bottom: 5px;
}
.row-scout {
  width: 100%;
  height: 100%;
  display: table;
}
.row-scout p {
  padding: 10px;
  display: table-cell;
  vertical-align: middle;
}
.row-scout:nth-of-type(2n) {
  /* background-color: #eeeeee; */
}
.chart {
  width: calc(100% - 10px);
  height: 100%;
}
.t-container {
  width: 100%;
  border-collapse: collapse;
}
.t-container td {
  text-align: center;
  border-top: none !important;
  border-bottom: none !important;
}
.t-container th {
  font-size: 16px;
}
.t-scouts {
  /* width: 100%; */
  font-size: 0.8rem;
  line-height: 0.8rem;
}
.t-scouts p {
  text-align: center;
  display: inline-block;
}
.t-info {
  height: 100%;
  width: 150px;
  padding: 10px 0;
}
.t-img {
  margin: 0 10px;
  height: 23px;
}
.t-s-neg {
  color: lightcoral;
  margin-left: 5px;
}
.t-s-pos {
  color: lightgreen;
  margin-left: 5px;
}
.addScroll {
  height: 500px !important;
  display: block;
  overflow-y: auto;
}
.media {
  display: table;
  background-color: blanchedalmond;
}
.times {
  height: 300px;
  background-color: rgb(226, 128, 128);
}
.times-2 {
  height: 200px;
  background-color: coral;
}
.media-2 {
  height: 200px;
  background-color: rgb(74, 141, 143);
}
</style>
