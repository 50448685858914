<template>
  <ve-pie :data="chartData" :colors="chartColors" :settings="chartSettings" :height="chartHeight" :width="chartWidth" :theme-name="'dark'"></ve-pie>
</template>

<script>
import VePie from 'v-charts/lib/pie.common'

export default {
  props: ['chartData','chartColors','chartSettings','chartHeight','chartWidth'],
  components: {
    've-pie': VePie
  }
}
</script>

<style>

</style>

<!--
<script>
import { Pie, mixins } from "vue-chartjs"
export default {
  extends: Pie,
  props: ["data", "options"],
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.data, this.options);
  }
}
</script>
-->